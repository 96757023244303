import { GrowProjectInvestment, InyovaGrowAccount, GrowProjectStatus, InyovaGrowRiskGroups, InyovaGrowRiskSurveyQuestion } from '@shared/models/Grow';

export const getGrowProjectBadgeDetails = (status: GrowProjectStatus): { type: string; key: string } => {
  switch (status) {
    case GrowProjectStatus.SELECTED:
      return { type: 'info', key: 'selected' };
    case GrowProjectStatus.INTERESTED:
      return { type: 'info', key: 'interested' };
    case GrowProjectStatus.COMMITTED:
      return { type: 'secondary', key: 'committed' };
    case GrowProjectStatus.IN_TRANSIT:
      return { type: 'warning', key: 'processing' };
    case GrowProjectStatus.INVESTED:
      return { type: 'success', key: 'invested' };
    case GrowProjectStatus.REPAID:
      return { type: 'neutral', key: 'repaid' };
    default:
      return { type: 'hidden', key: '' };
  }
};

export const getPercentageOfPortfolio = (growAccount: InyovaGrowAccount, amountToInvest: number, projectCurrency: string): number => {
  if (growAccount.portfolio_value === 0) return 0;
  const isDifferentCurrency = projectCurrency.toLowerCase() !== growAccount.currency.toLowerCase();

  const amountInPortfolio = isDifferentCurrency ? amountToInvest * growAccount.eur_exchange_rate : amountToInvest;

  return ((amountInPortfolio / growAccount.portfolio_value) * 100) / 100;
};

export const getGroupedInvestments = (investments: GrowProjectInvestment[]): { status: GrowProjectStatus; amount: number }[] => {
  if (!investments || investments.length === 0) return [{ status: GrowProjectStatus.INITIAL, amount: 0 }];

  const groupedInvestments = investments.reduce((acc: { [key: string]: number }, investment: GrowProjectInvestment) => {
    if (!acc[investment.status]) {
      acc[investment.status] = 0;
    }

    acc[investment.status] += investment.amount !== null ? investment.amount : 0;
    return acc;
  }, {});

  const groupedInvestmentsArray = Object.entries(groupedInvestments).map(([status, amount]: [GrowProjectStatus, number]) => ({
    status,
    amount
  }));

  return groupedInvestmentsArray.sort((a, b) => {
    const order = [GrowProjectStatus.INTERESTED, GrowProjectStatus.INVESTED, GrowProjectStatus.COMMITTED];
    return order.indexOf(a.status) - order.indexOf(b.status);
  });
};
