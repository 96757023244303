// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .inyova-grow-project-content h2,
:host ::ng-deep .inyova-grow-project-content h3 {
  font-size: var(--fs-4);
  line-height: var(--flh-4);
  font-weight: var(--fw-700);
  letter-spacing: var(--fls-3);
  margin: 0;
  margin-bottom: var(--spacing-4);
}
:host ::ng-deep .inyova-grow-project-content p {
  font-size: var(--fs-4);
  line-height: var(--flh-5);
  font-weight: var(--fw-400);
  letter-spacing: var(--fls-3);
  margin-bottom: var(--spacing-8);
}

ion-content {
  --padding-start: var(--spacing-6);
  --padding-end: var(--spacing-6);
  --padding-bottom: var(--spacing-8);
}

.project-image {
  border-radius: var(--radius-5);
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: var(--spacing-8);
  width: 100%;
}
.project-image > img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h3 {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-8);
}

.inyova-grow-project-content {
  margin-bottom: var(--spacing-8);
}

.inyova-grow-figures {
  margin-bottom: var(--spacing-8);
}
.inyova-grow-figures h2 {
  margin-bottom: var(--spacing-3);
}

app-ds-content-switcher {
  display: block;
  margin-bottom: var(--spacing-8);
}

.inyova-grow-project-action {
  background: var(--color-core-brand-3-1);
  background: linear-gradient(0deg, var(--color-core-brand-3-1) 80%, rgba(249, 246, 240, 0) 100%);
  padding: var(--spacing-7) var(--spacing-6) var(--spacing-8) var(--spacing-6);
}

.project-progress {
  flex-direction: column;
  margin-bottom: var(--spacing-8);
}
.project-progress .header {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
}
.project-progress .info-row {
  display: flex;
  gap: var(--spacing-2);
  align-items: flex-start;
  justify-content: space-between;
}
.project-progress .info-icon {
  display: inline-flex;
}
.project-progress .progress-info {
  margin-top: var(--spacing-3);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
