import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { GrowFund, GrowProject, InyovaGrowAccount, InyovaGrowAccountStatus, GrowProjectStage, GrowProjectStatus } from '@app/shared/models/Grow';
import { getGrowProjectBadgeDetails } from '@grow/utils/inyova-grow.utils';
import { TrackingService } from '@shared/services/tracking.service';

export enum TabList {
  DETAILS,
  RISKS,
  OPPORTUNITIES,
  IMPACT
}

@Component({
  selector: 'app-inyova-grow-project-modal',
  templateUrl: './inyova-grow-project-modal.component.html',
  styleUrls: ['./inyova-grow-project-modal.component.scss']
})
export class InyovaGrowProjectModalComponent implements OnInit {
  @Input() project: GrowProject;
  @Input() fund: GrowFund;
  @Input() fundModal = false;
  @Input() isPerformancePage = false;
  @Input() growAccount: InyovaGrowAccount;
  @Input() language: string;
  @Input() showDepositWidget = false;
  @Input() groupedInvestments: { status: GrowProjectStatus; amount: number }[];

  activeTab = TabList.DETAILS;
  switcherTabs = [
    { id: TabList.DETAILS, value: 'GROW.project.tabs.details' },
    { id: TabList.RISKS, value: 'GROW.project.tabs.risks' },
    { id: TabList.OPPORTUNITIES, value: 'GROW.project.tabs.opportunities' },
    { id: TabList.IMPACT, value: 'GROW.project.tabs.impact' }
  ];

  projectStatus = GrowProjectStatus;
  projectStage = GrowProjectStage;
  accountStatus = InyovaGrowAccountStatus;

  tabList = TabList;

  showInfo = {
    progress: false,
    status: false,
    stage: false
  };

  projectProgress = null;

  constructor(
    private modalController: ModalController,
    private trackingService: TrackingService,
    private translateService: TranslateService
  ) {
    this.language = this.translateService.currentLang;
  }

  ngOnInit() {
    this.track(`[Grow project modal] Opened: ${this.fundModal ? 'Fund' : `Project ${this.project.id}`}`);
    if (!this.fundModal) {
      this.projectProgress = Math.floor((this.project.volume_secured_from_customers / this.project.inyova_volume) * 100);
      this.groupedInvestments.forEach((_investmentGroup, index) => {
        this.showInfo = {
          ...this.showInfo,
          [`status${index}`]: false
        };
      });
    }
  }

  switchTabs(tabId: TabList) {
    if (tabId === this.activeTab) {
      return;
    }
    this.track(`[Grow project modal] Tab Clicked: ${TabList[tabId]}`);
    this.activeTab = tabId;
  }

  onDismiss() {
    this.track(`[Grow project modal] Button: Dismiss ${this.fundModal ? 'Fund' : `Project ${this.project.id}`}`);
    this.modalController.dismiss();
  }

  onToggleProject() {
    this.modalController.dismiss({
      projectId: this.project.id,
      markProject: this.groupedInvestments[0].status === this.projectStatus.INITIAL
    });
  }

  onFinishInvestment() {
    this.modalController.dismiss({
      projectId: this.project.id,
      markAndInvest: this.groupedInvestments[0].status === this.projectStatus.INITIAL
    });
  }

  track(event: string) {
    this.trackingService.trackActivity(event);
  }

  toggleInfo(item: string) {
    this.showInfo[item] = !this.showInfo[item];
  }

  getInvestmentBadge(status: GrowProjectStatus): { type: string; key: string } {
    return getGrowProjectBadgeDetails(status);
  }
}
