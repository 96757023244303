import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-inyova-grow-project-doc',
  templateUrl: './inyova-grow-project-doc.component.html',
  styleUrls: ['./inyova-grow-project-doc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InyovaGrowProjectDocComponent {
  @Input() doc: string;
  @Input() title: string;

  openDocPDF(doc) {
    void Browser.open({
      url: doc
    });
  }
}
