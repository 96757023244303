// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inyova-grow-doc {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-3);
}
.inyova-grow-doc ion-icon {
  margin-right: var(--spacing-1);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
