import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Browser } from '@capacitor/browser';

import { GrowProject } from '@shared/models/Grow';

enum AgreementTypes {
  DOCUMENTS = 'documents',
  GENERAL = 'general',
  WAIVER = 'waiver'
}

@Component({
  selector: 'app-inyova-grow-investment-consent',
  templateUrl: './inyova-grow-investment-consent.component.html',
  styleUrls: ['./inyova-grow-investment-consent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InyovaGrowInvestmentConsentComponent implements OnInit {
  @Input() loading: boolean;
  @Input() selectedAmount: number;
  @Input() project: GrowProject;
  @Output() submitInvestment: EventEmitter<{ valid: boolean }> = new EventEmitter<{ valid: boolean }>();

  agreementTypes = AgreementTypes;
  agreements: { [key: string]: boolean };
  submitted = false;
  hasDocuments = false;

  ngOnInit() {
    this.agreements = {
      [AgreementTypes.DOCUMENTS]: false,
      [AgreementTypes.GENERAL]: false,
      [AgreementTypes.WAIVER]: false
    };
    this.hasDocuments = typeof this.project.investment_documents !== 'undefined' || this.project.investment_documents !== null;
  }

  openDocPDF(url: string) {
    void Browser.open({
      url
    });
  }

  onAccept(type: AgreementTypes) {
    this.agreements[type] = !this.agreements[type];
  }

  onSubmit() {
    this.submitted = true;
    const valid = !Object.values(this.agreements).some((value) => value === false);
    this.submitInvestment.emit({ valid });
  }
}
