import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { getRiskType, RISK_RATIO_TO_SLIDER_STEP } from '@inyova/grow';
import { IonContent, ModalController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as InyovaGrowActions from '@grow/inyova-grow.actions';
import * as fromInyovaGrow from '@grow/inyova-grow.reducers';

import { GrowProject, InyovaGrowAccount, GrowProjectStage } from '@app/shared/models/Grow';

enum InvestmentSteps {
  SETUP,
  CONFIRM,
  DONE
}

@Component({
  selector: 'app-inyova-grow-investment-modal',
  templateUrl: './inyova-grow-investment-modal.component.html',
  styleUrls: ['./inyova-grow-investment-modal.component.scss']
})
export class InyovaGrowInvestmentModalComponent implements OnInit, OnDestroy {
  protected readonly onDestroy$ = new Subject<void>();
  @Input() growAccount: InyovaGrowAccount;
  @Input() projectId: string;
  @Input() language: string;
  activeHeaderStep = 1;
  currentStep: InvestmentSteps;
  investmentSteps = InvestmentSteps;
  selectedAmount: null | number = null;
  project$: Observable<GrowProject>;
  projectFinishStatus: 'success' | 'reached' | '';
  loading = false;
  riskLevel = 'balanced';
  inyovaGrowProjectStage = GrowProjectStage;

  @ViewChild(IonContent) content: IonContent;

  constructor(
    private modalController: ModalController,
    private el: ElementRef,
    private store: Store
  ) {}

  ngOnInit() {
    this.currentStep = this.investmentSteps.SETUP;
    this.riskLevel = getRiskType(RISK_RATIO_TO_SLIDER_STEP[this.growAccount.projects_percentage]);
    this.project$ = this.store.select(fromInyovaGrow.selectGrowProjectById(this.projectId));
    this.riskLevel = getRiskType(RISK_RATIO_TO_SLIDER_STEP[this.growAccount.projects_percentage]);

    this.store
      .select(fromInyovaGrow.selectInyovaGrowFinishStatus)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((status) => (this.projectFinishStatus = status));

    this.store
      .select(fromInyovaGrow.selectInyovaGrowInvestmentDone)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((success: boolean) => {
        if (success) {
          void this.content.scrollToTop(0);
          this.activeHeaderStep = 3;
          this.currentStep = this.investmentSteps.DONE;
        }
      });

    this.store
      .select(fromInyovaGrow.selectInyovaGrowInvestmentLoading)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((loading: boolean) => {
        this.loading = loading;
      });
  }

  ngOnDestroy() {
    this.store.dispatch(InyovaGrowActions.finishGrowProjectInvestmentReset());
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  onDepositFund() {
    this.modalController.dismiss({ openDepositModal: true });
  }

  onSubmitAmount({ amount }: { amount: number }) {
    void this.content.scrollToTop(0);
    this.activeHeaderStep = 2;
    this.currentStep = this.investmentSteps.CONFIRM;
    this.selectedAmount = amount;
  }

  onSubmitInvestment({ valid }: { valid: boolean }) {
    if (!valid) {
      setTimeout(() => {
        const firstInvalidCheckbox: HTMLElement | null = this.el.nativeElement.querySelector('.has-error');
        if (firstInvalidCheckbox !== null) {
          void this.content.scrollToPoint(0, firstInvalidCheckbox.offsetTop, 350);
        }
        return;
      }, 0);
      return;
    }

    if (this.loading) {
      return;
    }
    this.store.dispatch(InyovaGrowActions.finishGrowProjectInvestment({ projectId: this.projectId, amount: this.selectedAmount }));
  }
}
