import { InyovaGrowProjectModalComponent } from '@app/inyova-grow/modals/inyova-grow-project-modal/inyova-grow-project-modal.component';
import { InyovaGrowDepositFundModalComponent } from '@grow/modals/inyova-grow-deposit-fund-modal/inyova-grow-deposit-fund-modal.component';
import { InyovaGrowDepositModalComponent } from '@grow/modals/inyova-grow-deposit-modal/inyova-grow-deposit-modal.component';
import { InyovaGrowImpactProjectModalComponent } from '@grow/modals/inyova-grow-impact-project-modal/inyova-grow-impact-project-detail.component';
import { InyovaGrowInvestmentConsentComponent } from '@grow/modals/inyova-grow-investment-modal/components/inyova-grow-investment-consent/inyova-grow-investment-consent.component';
import { InyovaGrowInvestmentSetupComponent } from '@grow/modals/inyova-grow-investment-modal/components/inyova-grow-investment-setup/inyova-grow-investment-setup.component';
import { InyovaGrowInvestmentModalComponent } from '@grow/modals/inyova-grow-investment-modal/inyova-grow-investment-modal.component';
import { InyovaGrowProjectDocComponent } from '@grow/modals/inyova-grow-project-modal/components/inyova-grow-project-doc/inyova-grow-project-doc.component';

export const components = [
  InyovaGrowProjectModalComponent,
  InyovaGrowDepositModalComponent,
  InyovaGrowInvestmentConsentComponent,
  InyovaGrowProjectDocComponent,
  InyovaGrowDepositFundModalComponent,
  InyovaGrowInvestmentModalComponent,
  InyovaGrowInvestmentSetupComponent,
  InyovaGrowImpactProjectModalComponent
];

export { InyovaGrowProjectModalComponent } from '@app/inyova-grow/modals/inyova-grow-project-modal/inyova-grow-project-modal.component';
export { InyovaGrowDepositModalComponent } from '@grow/modals/inyova-grow-deposit-modal/inyova-grow-deposit-modal.component';
export { InyovaGrowImpactProjectModalComponent } from '@grow/modals/inyova-grow-impact-project-modal/inyova-grow-impact-project-detail.component';
export { InyovaGrowInvestmentConsentComponent } from '@grow/modals/inyova-grow-investment-modal/components/inyova-grow-investment-consent/inyova-grow-investment-consent.component';
export { InyovaGrowDepositFundModalComponent } from '@grow/modals/inyova-grow-deposit-fund-modal/inyova-grow-deposit-fund-modal.component';
export { InyovaGrowInvestmentModalComponent } from '@grow/modals/inyova-grow-investment-modal/inyova-grow-investment-modal.component';
export { InyovaGrowInvestmentSetupComponent } from '@grow/modals/inyova-grow-investment-modal/components/inyova-grow-investment-setup/inyova-grow-investment-setup.component';
